import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBreakpoints from 'src/hooks/useBreakpoints'
import useEnum from 'src/hooks/useEnum'
import { useHistory } from 'react-router-dom'
import { Formik, Form, FormikConfig, Field } from 'formik'
import * as Yup from 'yup'
import { useTranslation, Trans } from 'react-i18next'
import useAppState from 'src/hooks/useAppState'

import styled, { css } from 'styled-components'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'
import { ResponsiveGridxs2, XsPaddingZeroContainer } from 'src/modules/common'

import {
  createCourseStrategy,
  selectedCourseStrategySelector,
  updateDialogVisibility,
  updateDownloadDialogVisibility,
} from 'src/store/courseStrategySlice'
import { openToast, getErrorToast } from 'src/store/toastSlice'

import { Languages, DateFormat, Routes } from 'src/utils/constants'
import { RoundType, HolesNumber, NinePlayed } from 'src/utils/golfConstants'
import { dateTransform } from 'src/utils/helpers'

import { ArrowForward, FormDatePicker } from 'src/components/common'
import Autocomplete from 'src/components/inputs/Autocomplete'
import LoaderButton from 'src/components/inputs/LoaderButton'
import FormToggleButton from 'src/components/inputs/FormToggleButton'
import FormTextField from 'src/components/inputs/FormTextField'
import {
  Course,
  getCoursesList,
  getTeeList,
  Tee,
} from '../../../service/courseService'

const I18N_KEY = 'CourseDetails'

interface Props {
  isEditDisabled?: boolean
}

export interface FormValues {
  datePlayed: Date
  course: Course | null
  courseId: string
  numberOfHoles: HolesNumber
  roundType: RoundType
  tee: Tee | null
  ninePlayed: string | null
  courseNotes: string
}

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    width: 650px;
    padding: ${theme.spacing(6, 4)};
    margin: ${theme.spacing(4, 0, 0)};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    ${theme.breakpoints.down('xs')} {
      margin: 0;
      padding: ${theme.spacing(4, 2)};
    }
  `
)

const SectionTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1)}px;
  `
)

const TypeTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)}px;
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(6)}px;
    width: 250px;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
)

const date = new Date()

const CreateCourseStrategy: React.FC<Props> = ({ isEditDisabled = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isCoachView, playerUuid } = useAppState()

  const { t, i18n } = useTranslation()
  const { isSmallScreen } = useBreakpoints()
  const courseStrategy = useSelector(selectedCourseStrategySelector)
  const [courses, setCourses] = useState<Course[]>([])
  const [tees, setTees] = useState<Tee[]>([])
  const [courseLoading, setCourseLoading] = useState(false)
  const [teeLoading, setTeeLoading] = useState(false)
  const apiCallMinimumWaitTimer = useRef<ReturnType<typeof setTimeout> | null>(
    null
  )

  const datePlayed = courseStrategy?.datePlayed || date
  const roundType = courseStrategy?.roundType || RoundType.Practice
  const numberOfHoles = courseStrategy?.numberOfHoles || HolesNumber.Nine
  const ninePlayed = courseStrategy?.ninePlayed || NinePlayed.Front
  const courseId = courseStrategy?.courseId || ''
  const courseNotes = courseStrategy?.courseNotes || ''

  const { options: roundOptions } = useEnum(RoundType, 'RoundType')
  const { options: holesOptions } = useEnum(HolesNumber, 'HolesNumber')
  const { options: ninePlayedOptions } = useEnum(NinePlayed, 'NinePlayed')

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        datePlayed: Yup.date()
          .transform(dateTransform)
          .required(
            t(
              `${I18N_KEY}.dateRequiredErrorMessage`,
              'Please enter a valid birth date'
            )
          ),
        roundType: Yup.string().required(
          t(
            `${I18N_KEY}.roundTypeRequiredErrorMessage`,
            'Please select the round type'
          )
        ),
        numberOfHoles: Yup.string().required(
          t(
            `${I18N_KEY}.numberOfHolesRequiredErrorMessage`,
            'Please select the number of holes'
          )
        ),
        ninePlayed: Yup.string().when('numberOfHoles', {
          is: HolesNumber.Nine,
          then: Yup.string().required(
            t(
              `${I18N_KEY}.ninePlayedRequiredErrorMessage`,
              'Please select the which 9 is being played'
            )
          ),
        }),
        course: Yup.string().required(
          t(
            `${I18N_KEY}.courseNameRequiredErrorMessage`,
            'Please specify the course name'
          )
        ),
        tee: Yup.string().required(
          t(`${I18N_KEY}.teeRequiredErrorMessage`, 'Please specify a tee')
        ),
      }),
    [t]
  )

  const formikOptions: FormikConfig<FormValues> = {
    initialValues: {
      roundType,
      datePlayed,
      numberOfHoles,
      course: null,
      courseId,
      tee: null,
      ninePlayed,
      courseNotes,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({ courseId, ninePlayed, tee, ...restValues }) => {
      const course: Course | null = restValues.course
      if (!course) {
        throw new Error('No matching course found.')
      }
      courseId = course.courseId

      // If 18 Holes selected clear the value for "9 BEING PLAYED"
      if (restValues.numberOfHoles === HolesNumber.Eighteen) {
        ninePlayed = null
      }
      const payload = {
        courseId,
        ninePlayed,
        teeName: tee!.name,
        courseName: course.courseName,
        ...restValues,
      }

      try {
        await dispatch(createCourseStrategy(payload))
        history.push(
          isCoachView
            ? Routes.CoachPlayerCourseStrategy.replace(
                ':playerUuid',
                playerUuid ?? ''
              )
            : Routes.CourseStrategy
        )
        dispatch(updateDialogVisibility({ isOpen: false }))
        dispatch(updateDownloadDialogVisibility({ isDownloadDialogOpen: true }))
      } catch (error: any) {
        dispatch(openToast(getErrorToast(error.message)))
      }
    },
  }

  const NinePlayedFields: React.FC<{ isEditDisabled: boolean }> = ({
    isEditDisabled,
  }) => {
    const { t } = useTranslation()
    return (
      <>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6}>
          <FormToggleButton
            name="ninePlayed"
            disabled={isEditDisabled}
            label={t(`${I18N_KEY}.ninePlayedLabel`, '9 Being Played')}
            options={ninePlayedOptions}
          />
        </Grid>
      </>
    )
  }

  const getCourseOptions = async (courseName: string) => {
    if (apiCallMinimumWaitTimer) {
      clearTimeout(apiCallMinimumWaitTimer.current!)
    }
    apiCallMinimumWaitTimer.current = setTimeout(async () => {
      if (courseName && courseName.length > 2) {
        setCourseLoading(true)
        const courses: Course[] = await getCoursesList(courseName)
        setCourses(courses)
      } else {
        setCourses([])
      }
      setCourseLoading(false)
    }, 1500)
  }

  const formatCourseName = (course: Course): string => {
    if (course) {
      const MAX_LENGTH_COURSE_NAME = 40
      const MAX_LENGTH_CITY = 10
      const isFullNameWithinLimit =
        course.city.length + course.courseName.length <=
        MAX_LENGTH_COURSE_NAME + MAX_LENGTH_CITY
      if (
        (course.courseName.length <= MAX_LENGTH_COURSE_NAME ||
          course.city.length <= MAX_LENGTH_CITY) &&
        isFullNameWithinLimit
      ) {
        return `${course.courseName} (${course.city}${
          course.state ? `/${course.state}` : ''
        })`
      }
      return `${
        course.courseName.length <= MAX_LENGTH_COURSE_NAME
          ? course.courseName
          : `${course.courseName.substring(0, MAX_LENGTH_COURSE_NAME - 3)}...`
      } (${
        course.city.length <= MAX_LENGTH_CITY
          ? course.city
          : `${course.city.substring(0, MAX_LENGTH_CITY - 3)}...`
      }${course.state ? `/${course.state}` : ''})`
    }
    return ''
  }

  const selectCourse = async (course: any): Promise<void> => {
    try {
      const selectedCourse = course as Course
      if (selectedCourse) {
        setTeeLoading(true)
        const tees: Tee[] = await getTeeList(selectedCourse.courseId)
        setTees(tees)
      } else {
        setTees([])
      }
    } catch (error) {
      console.error('Course strategy selection error:', error)
    } finally {
      setTeeLoading(false)
    }
  }

  const isTeeNameValid = (teeName: string): boolean => {
    const invalidTeeNames = ['member', 'members', 'combo']
    const invalidCharacter = '/'
    return (
      !invalidTeeNames.includes(teeName) && !teeName.includes(invalidCharacter)
    )
  }

  const getTotalYards = (
    tee: Tee,
    numberOfHoles: HolesNumber,
    ninePlayed: string | null
  ) => {
    if (numberOfHoles === HolesNumber.Eighteen) {
      return tee.ydsTotal
    } else if (ninePlayed === NinePlayed.Front) {
      return tee.yds1to9
    } else {
      return tee.yds10to18
    }
  }

  const filterTeeOptions = () => {
    return tees.filter(tee => isTeeNameValid(tee.name.toLowerCase()))
  }

  const formatTeeName = (
    tee: Tee,
    numberOfHoles: HolesNumber,
    ninePlayed: string | null
  ): string => {
    if (tee) {
      return `${tee.name} - ${getTotalYards(tee, numberOfHoles, ninePlayed)}y`
    }
    return ''
  }

  return (
    <XsPaddingZeroContainer>
      <StyledPaper elevation={0}>
        <SectionTitle variant="h4">
          <Trans i18nKey={`${I18N_KEY}.courseInfo`}>Course Information</Trans>
        </SectionTitle>
        <Formik {...formikOptions}>
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <ResponsiveGridxs2 container spacing={isSmallScreen ? 2 : 4}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">
                    <Trans i18nKey={`${I18N_KEY}.dateLabel`}>Date</Trans>
                  </Typography>
                  <Field
                    autoOk
                    fullWidth
                    helperText=""
                    name="datePlayed"
                    inputVariant="outlined"
                    disabled={isEditDisabled}
                    component={FormDatePicker}
                    views={undefined}
                    openTo={undefined}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ endAdornment: <CalendarIcon /> }}
                    format={DateFormat[i18n.language as Languages].datePicker}
                  />
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <FormToggleButton
                    name="roundType"
                    options={roundOptions}
                    disabled={isEditDisabled}
                    label={t(`${I18N_KEY}.roundTypeLabel`, 'Round type')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <FormToggleButton
                    name="numberOfHoles"
                    disabled={isEditDisabled}
                    label={t(
                      `${I18N_KEY}.numberOfHolesLabel`,
                      'Number of holes'
                    )}
                    options={holesOptions}
                  />
                </Grid>
                {values.numberOfHoles === HolesNumber.Nine ? (
                  <NinePlayedFields isEditDisabled={isEditDisabled} />
                ) : null}
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={10}>
                  <Box mt={3}>
                    <TypeTitle variant="h4">
                      <Trans i18nKey={`${I18N_KEY}.courseTitle`}>Course</Trans>
                    </TypeTitle>
                    <Autocomplete
                      name="course"
                      getOptionLabel={(course: Course) =>
                        formatCourseName(course)
                      }
                      options={courses}
                      label={t(`${I18N_KEY}.courseNameLabel`, 'Course Name')}
                      placeholder={t(
                        `${I18N_KEY}.coursePlaceHolder`,
                        'Please select a Course'
                      )}
                      fullWidth
                      onChange={(e, value: any) => {
                        selectCourse(value)
                        setFieldValue('course', value)
                        setFieldValue('tee', null)
                      }}
                      onChangedInput={value => getCourseOptions(value)}
                      loading={courseLoading}
                      data-cy="course-select"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Autocomplete
                    name="tee"
                    getOptionLabel={(tee: Tee) =>
                      formatTeeName(
                        tee,
                        values.numberOfHoles,
                        values.ninePlayed
                      )
                    }
                    options={filterTeeOptions()}
                    label={t(`${I18N_KEY}.teeLabel`, 'Tees')}
                    placeholder={t(
                      `${I18N_KEY}.coursePlaceHolder`,
                      'Please select a Tee'
                    )}
                    fullWidth
                    loading={teeLoading}
                    data-cy="course-tee"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <FormTextField
                    fullWidth
                    name="courseNotes"
                    disabled={isEditDisabled}
                    label={t(`${I18N_KEY}.courseNotesLabel`, 'Notes')}
                  />
                </Grid>
              </ResponsiveGridxs2>
              <ButtonContainer>
                <LoaderButton
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  endIcon={<ArrowForward />}
                >
                  <Trans i18nKey={`${I18N_KEY}.submitButtonLabel`}>
                    Create Strategy
                  </Trans>
                </LoaderButton>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </StyledPaper>
    </XsPaddingZeroContainer>
  )
}

export default CreateCourseStrategy
