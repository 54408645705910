import React, { KeyboardEvent } from 'react'
import { isValid } from 'date-fns'
import { Trans } from 'react-i18next'
import { ThunkDispatch, CombinedState, Action } from '@reduxjs/toolkit'

import {
  Routes,
  Greeting,
  StorageKeys,
  DECIMAL_PLACES,
  UserType,
  DeploymentLocation,
  DEPLOYMENT_LOCATION,
  Languages,
} from 'src/utils/constants'
import {
  Par,
  Round,
  MetricId,
  RoundType,
  Measurement,
  RootCategory,
  RootStrokesGained,
} from 'src/utils/golfConstants'
import history from 'src/utils/history'
import { RootState } from 'src/store/rootReducer'
import { setAuthTokenHeader } from 'src/utils/api'
import { reinitialiseAuth } from 'src/store/authSlice'
import { reinitialiseRound } from 'src/store/roundSlice'
import { reinitialisePlayer } from 'src/store/playerSlice'
import { reinitialiseSummary } from 'src/store/summarySlice'
import { clearLocal, setItemWithExpiry } from 'src/utils/localStorage'
import { reinitialiseUser, userTypeSelector } from 'src/store/userSlice'
import { reinitialise as reinitialiseSubscription } from 'src/store/subscriptionSlice'

const I18N_KEY = 'Helpers'

export const isNumber = (value: any) =>
  typeof value === 'number' && isFinite(value)

export const isFloat = (num: number) => num % 1 !== 0

export const getAbsolutelyFixedNumber = (value: number) =>
  Math.abs(value).toFixed(DECIMAL_PLACES)

export const roundTo2DP = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100

export const average = (numbers: number[]) =>
  numbers.reduce((a, b) => a + b, 0) / numbers.length

export const handleNumberFormat = (
  num: number,
  makeAbsolute: boolean = false,
  decimals?: number
) => {
  if (isFloat(num)) {
    return makeAbsolute
      ? getAbsolutelyFixedNumber(num)
      : num?.toFixed(decimals ?? DECIMAL_PLACES)
  }

  return makeAbsolute ? Math.abs(num) : num
}

export const acceptNumbersOnly = (
  e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const isNumber = /^\d$/.test(e.key) && !e.shiftKey && !e.altKey && !e.metaKey
  const isArrow = e.keyCode >= 37 && e.keyCode <= 40
  const isDelete = e.key === 'Backspace' || e.key === 'Delete'
  const isPaste = e.metaKey && e.key === 'v'
  if (!(isNumber || isArrow || isDelete || isPaste)) {
    e.preventDefault()
  }
}

export const getUserInfo = (state: RootState) => {
  const playerUuid = state.player.uuid
  const userType = userTypeSelector(state)

  return { isPlayer: userType === UserType.Player, playerUuid }
}

export const authRequestFail = (
  dispatch: ThunkDispatch<CombinedState<RootState>, unknown, Action<string>>
) => {
  clearLocal()
  dispatch(reinitialiseAuth())
  dispatch(reinitialiseUser())
  dispatch(reinitialiseRound())
  dispatch(reinitialisePlayer())
  dispatch(reinitialiseSummary())
  dispatch(reinitialiseSubscription())
  history.push(Routes.SignIn)
}

export const authRequestSuccess = (token: string, refreshToken: string) => {
  setAuthTokenHeader(token)
  setItemWithExpiry(StorageKeys.Token, token)
  setItemWithExpiry(StorageKeys.RefreshToken, refreshToken)
}

export const dateTransform = (curVal: Date) => (isValid(curVal) ? curVal : '')

export const parsePar = (value: Par) => {
  switch (value) {
    case Par.Three:
      return 3
    case Par.Four:
      return 4
    case Par.Five:
      return 5
    default:
  }
}

export const getPayloadFromResponse = (response: any) =>
  response.data && response.data.data

export const getSinglePayloadFromResponse = (response: any) => {
  const [payload] = getPayloadFromResponse(response) || []
  return payload
}

export const getErrorStatus = (error: any) => error.response?.status

export const getErrorCode = (error: any) => error.response?.data?.error_code

export const isForbiddenOrUnauthorised = (error: any) =>
  getErrorStatus(error) === 401 || getErrorStatus(error) === 403

export const isNotFound = (error: any) => getErrorStatus(error) === 404

export const getRoundTitle = (round: Partial<Round>) => {
  const roundNumber = round?.roundNumber ? ` (#${round.roundNumber})` : ''

  return (
    round?.roundType === RoundType.Practice
      ? round?.courseName
      : round?.tournamentName + roundNumber
  )!
}

export const getStrokesForOpportunity = (
  metricId: MetricId,
  categories: RootCategory[],
  strokesGained: RootStrokesGained | null
) =>
  metricId === MetricId.Drives
    ? strokesGained?.drives.overall
    : categories.find(category => metricId === category.metricId)?.strokesGained

export const getMeasurementSymbol = (
  measurement: Measurement,
  measurementLabels: any
) => {
  switch (measurement) {
    case Measurement.Percentage:
      return '%'
    case Measurement.MPH:
    case Measurement.Feet:
    case Measurement.Yards:
    case Measurement.Meters:
    case Measurement.PuttsOfTen:
    case Measurement.StrokesGained:
      return measurementLabels[measurement]
    case Measurement.Number:
    case Measurement.Score:
    default:
      return ''
  }
}

export const getGreeting = () => {
  const hourOfDay = new Date().getHours()
  if (hourOfDay < 12) {
    return Greeting.Morning
  }
  if (hourOfDay < 17) {
    return Greeting.Afternoon
  }
  return Greeting.Evening
}

export const centsToDollar = (cents: number, decimals: number = 2): string =>
  (cents / 100).toFixed(decimals)

export const getImprovementCaption = (
  improvementPotential: number,
  referencePoint: string
) => {
  const improvementPotentialFormatted =
    getAbsolutelyFixedNumber(improvementPotential)
  // When they are below the reference (0.0) show potential SG amount
  // return improvementPotential < 0 ? (
  //   <Trans i18nKey={`${I18N_KEY}.lessThanReference`}>
  //     Gain {{ improvementPotentialFormatted }}SG by improving these to{' '}
  //     {{ referencePoint }} (0.0)
  //   </Trans>
  // ) : (
  //   <Trans i18nKey={`${I18N_KEY}.greaterThanReference`}>
  //     You will gain the most strokes by improving these areas
  //   </Trans>
  // )
  return (
    <Trans i18nKey={`${I18N_KEY}.lessThanReference`}>
      Gain {{ improvementPotentialFormatted }}SG by improving these to{' '}
      {{ referencePoint }} (0.0)
    </Trans>
  )
}

/**
 * Format currency in local format depending on deployment region
 * @param value
 * @param rounded
 */
export const formatCurrency = (value: number, rounded = false) => {
  switch (DEPLOYMENT_LOCATION) {
    case DeploymentLocation.Global:
      return `$${centsToDollar(value, rounded ? 0 : 2)}`
    case DeploymentLocation.China:
      return `¥ ${centsToDollar(value, rounded ? 0 : 2)}`
    default:
      return `${value}`
  }
}

/**
 * Format currency in local format depending on deployment region along
 * with any relevant denomination info (e.g. USD$100)
 * @param value
 * @param rounded
 */
export const formatCurrencyFull = (value: number, rounded = false) => {
  switch (DEPLOYMENT_LOCATION) {
    case DeploymentLocation.Global:
      return `USD${formatCurrency(value, rounded)}`
    default:
      return formatCurrency(value, rounded)
  }
}

/**
 * Formats a supplied phoneNumber
 * @param phoneNumber
 */
export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return null
}

/**
 * Makes the first character of the supplied text a Capital (Uppercased).
 * @param text
 */
export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const convertYardsToMetersForLabels = (
  text: string,
  language: Languages
): string => {
  const meterText = language === Languages.English ? 'Meters' : '米'
  return text.replace(
    /(\d+)\s*(?:-|to)?\s*(\d+)?\s*(Yards|yards|码)/gi,
    (_, min, max, unit) => {
      const convertToMeters = (yards: string) =>
        Math.round(parseInt(yards) * 0.9144)

      const newUnit =
        unit === 'Yards' ? 'Meters' : unit === 'yards' ? 'meters' : meterText

      const minMeters = convertToMeters(min)
      return max
        ? `${minMeters}-${convertToMeters(max)} ${newUnit}`
        : `${minMeters} ${newUnit}`
    }
  )
}
